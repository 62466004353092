// Translated
// Migrated
<template lang="pug">
.w-100(:class="containerClasses")
  .p-1
    input#newsletterCheckbox.special-checkbox(
      v-model="checked",
      type="checkbox",
    )
    label.mb-0.font-weight-medium.bg-transparent(for="newsletterCheckbox", data-i18n="newsletterCheckbox") {{ $t('newsletterCheckbox') }}

  template(v-if="useAirports")
    b-skeleton.mb-0(v-if="loading", height="38px")
    select.form-control.border-bottom-0.border-left-0.border-right-0(
      v-else
      v-model="airport"
      :disabled="!checked"
    )
      option(
        v-for="ap in newsletterAirports"
        :key="ap.id"
        :value="ap.id"
      ) {{ ap.name }}
</template>

<script setup>
import localStorageKeys from '@layers/web/constants/local-storage-keys'

const { trackNewsletterSignup } = useTracking()
const rootStore = useRootStore()
const localeStore = useLocaleStore()

const props = defineProps({
  email: {
    type: String,
    required: true
  },

  /**
   * @prop {string} prefilledAirport
   * Iata id of airport, ex CPH (Köpenhamn-Kastrup)
   */
  prefilledAirportIata: {
    type: String,
    default: null
  },

  initialCheckedState: {
    type: Boolean,
    default: true
  },

  /**
   * @prop {'standard' | 'bluebox'} variant
   */
  variant: {
    type: String,
    default: 'standard'
  }
})

const airport = ref(undefined)
const loading = ref(false)
const checked = ref(props.initialCheckedState)

const { newsletterAirports } = storeToRefs(rootStore)
const {
  locale,
  hasLocaleAirportSpecificNewsletter: useAirports
} = storeToRefs(localeStore)

const containerClasses = computed(() => {
  const classes = []

  if (useAirports.value) {
    classes.push('border mt-2 mb-4')
  } else if (props.variant !== 'bluebox') {
    classes.push('ml-n2 mt-n1 mb-2')
  }

  if (props.variant === 'bluebox') {
    classes.push('form-container-blue')

    if (useAirports.value) {
      classes.push('p-0 border-gray')
    } else {
      classes.push('px-3 py-1')
    }
  } else {
    classes.push('border-gray')
  }

  return classes.join(' ')
})

onMounted(async () => {
  if (useAirports.value) {
    await expand()

    if (props.prefilledAirportIata) {
      let newsletterAirportIata = props.prefilledAirportIata

      if (props.prefilledAirportIata === 'ARN') {
        newsletterAirportIata = 'STO'
      }

      const prefilledAirportExistsAsNewsletter = newsletterAirports.value
        .find(newsletterAirport => newsletterAirport.id === newsletterAirportIata)
      if (prefilledAirportExistsAsNewsletter) {
        airport.value = newsletterAirportIata
      }
    }
  }
})

const expand = async () => {
  loading.value = true

  if (!newsletterAirports.value?.length) {
    await rootStore.fetchNewsletterLists()
  }

  airport.value = newsletterAirports.value?.[0]?.id

  loading.value = false
}

const registerEmailIfChecked = async () => {
  if (!checked.value) {
    return
  }

  trackNewsletterSignup(airport.value)

  const registerParams = {
    email: props.email,
  }

  if (useAirports.value) {
    registerParams.airpiort = airport.value // yes, it's a typo in the endpoint
  }

  try {
    const { data: res } = await apiFetch(`/${locale.value}/newsletter-register`, {
      method: 'POST',
      body: registerParams
    })
    const result = res
    if (result && process.client) {
      try {
        localStorage.setItem(localStorageKeys.hideNewsletter, true)
      } catch {
        // do nothing
      } // Storage Exceeded

      success = true
    }
  } catch {
    // do nothing
  }
}

defineExpose({
  registerEmailIfChecked,
  checked
})
</script>
